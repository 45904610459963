.badge{
    padding: 4px 8px 3px 3px !important;
    font-weight: 400 !important;
    font-size: 0.7rem !important;
}

.badge .icon{
    margin-right: 3px;
    margin-left: 3px;
    margin-bottom: 2px;
    vertical-align: middle !important;
    line-height: 1.2 !important;
    font-size: 1rem !important;
    font-weight: 700 !important;
}