/* input:focus {
    box-shadow: 0 0 0 0.15rem #00727d59 !important;
}

input:focus.error{
    box-shadow: 0 0 0 0.15rem #ff000099 !important;
    border: none;
}

.error{
    box-shadow: 0 0 0 0.15rem #ff000059 !important;
    border: none;
} */

.input-login{
    font-size: 14px;
    padding: 4px 4px 4px 8px;
    background-color: #fbfcfc;
    border: solid #fbfcfc 1px;
    transition: all 0.15s ease;
}

.input-login:hover{
    border: solid #ebebeb 1px;
    background-color: #f3f4f5;
}

.input-login:focus{
    border: solid #ebebeb 1px;
    box-shadow: inset 0px -2px 0px 0px #00727d88;
    background-color: #ffffff;
}

.input-login:hover:focus{
    border: solid #ebebeb 1px;
    box-shadow: inset 0px -2px 0px 0px #00727d88;
}

.input-login.error,
.input-login:hover.error,
.input-login:focus.error
.input-login:hover:focus.error{
    box-shadow: inset 0px -2px 0px 0px #ff000059;
}

.input-login ~ label::after{
    background-color: transparent !important;
}