.fetch-select button {
  color: #212529;
  padding-left: 17px;
  padding-bottom: 8px;
  text-align: left;
  border-radius: 0%;
  transition: all 0.2s ease;
  border-bottom: solid 1px rgb(0, 0, 0, 0.2) !important;
}

.fetch-select button,
.fetch-select button:active,
.fetch-select button:hover {
  border: 0;
  background-color: transparent !important;
  border-bottom: solid 1px rgb(0, 0, 0, 0.2) !important;
}

.fetch-select button.error,
.fetch-select button.error:active,
.fetch-select button.error:hover {
  color: #ff0000aa !important;
  box-shadow: inset 0px -3px 0px 0px #ff000059 !important;
  border-bottom: none !important;
}

.fetch-select button.error .novalue.show,
.fetch-select button.error:focus::placeholder,
.fetch-select button.error:hover::placeholder {
  color: #ff0000aa;
}

.fetch-select button.show {
  box-shadow: inset 0px -3px 0px 0px #00727d88;
}

.fetch-select .novalue {
  color: rgb(89, 92, 95, 1);
}

.fetch-select .menu {
  max-height: 200px;
  overflow-y: auto;
}

.fetch-select .menu::-webkit-scrollbar {
  width: 6px !important;
}

.fetch-select .menu::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 6px !important;
}

.fetch-select .menu::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 6px !important;
  margin-top: 5px;
  margin-bottom: 5px;
}

.fetch-select .novalue.show,
.fetch-select .novalue:hover {
  color: rgb(89, 92, 95, 0.5);
}

.fetch-select button::after {
  position: absolute;
  top: 65%;
  right: 0;
}
