.custom-modal .btn-close {
  transition: all 0.3s;
}

.custom-modal .btn-close:hover {
  box-shadow: none;
  background-color: transparent;
}

.custom-modal .btn-close:focus {
  box-shadow: none;
  scale: 1.5;
  opacity: 0.5;
  background-color: transparent;
}

.custom-modal .modal-fullscreen .modal-content {
  background: none;
  padding: 15px;
}

.custom-modal .fullscreen-body {
  overflow: hidden;
  padding: 0;
}

.custom-modal .fullscreen-body .modal-title {
  border-bottom: solid 3px rgb(0, 0, 0, 0.15);
}

.modal-body-right {
  background-color: #fafafa;
  border-radius: 0px 30px 30px 0px;
}

.custom-modal .modal-fullscreen .modal-content::after {
  content: "";
  position: fixed;
  background: linear-gradient(
    180deg,
    #fefefe 0%,
    #fefefe 15%,
    #e5e5e5 40%,
    #e5e5e5 75%,
    #efefef 85%,
    #efefef 100%
  );
  border-radius: 30px;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  filter: blur(0px); /* Ajustez la valeur de flou selon vos préférences */
  z-index: -1; /* Assure que le fond flou est en arrière-plan */
}

.custom-modal .details {
  padding-right: 10px;
  height: calc(100% - 100px);
  overflow-y: auto;
}

.custom-modal .content {
  padding-right: 10px;
  height: calc(100% - 75px);
  overflow-y: auto;
}

.custom-modal .content::-webkit-scrollbar {
  width: 6px !important;
}

.custom-modal .content::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 6px !important;
}

.custom-modal .content::-webkit-scrollbar-track {
  border-radius: 6px !important;
  margin-bottom: 5px;
}

.custom-modal .details::-webkit-scrollbar {
  width: 6px !important;
}

.custom-modal .details::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.1);
  border-radius: 6px !important;
}

.custom-modal .details::-webkit-scrollbar-track {
  background-color: rgb(0, 0, 0, 0);
  border-radius: 6px !important;
  margin-bottom: 5px;
}

.custom-modal .button-menu {
  margin: 0px;
  padding: 0px;
  background: none;
  border-radius: 0px !important;
  border: none;
  transition: all 0.2s !important;
}

.custom-modal .button-menu:hover,
.custom-modal .button-menu:active,
.custom-modal .button-menu:active {
  background: none;
  font-weight: bold;
}
