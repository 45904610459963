@font-face {
    font-family: "Montserrat-Regular";   /*Can be any text*/
    src: local("Montserrat-Regular"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Bold";   /*Can be any text*/
    src: local("Montserrat-Bold"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-SemiBold";   /*Can be any text*/
    src: local("Montserrat-SemiBold"),
    url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Light";   /*Can be any text*/
    src: local("Montserrat-Light"),
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat-Thin";   /*Can be any text*/
    src: local("Montserrat-Thin"),
    url("./assets/fonts/Montserrat-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Light";   /*Can be any text*/
    src: local("Roboto-Light"),
    url("./assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto-Regular";   /*Can be any text*/
    src: local("Roboto-Regular"),
    url("./assets/fonts/Roboto-Regular.ttf") format("truetype");
}

.Montserrat-Regular{
    font-family: "Montserrat-Regular";
}

.Montserrat-Bold{
    font-family: "Montserrat-Bold";
}

.Montserrat-SemiBold{
    font-family: "Montserrat-SemiBold";
}

.Montserrat-Light{
    font-family: "Montserrat-Light";
}

.Montserrat-Thin{
    font-family: "Montserrat-Thin";
}

.Roboto-Light{
    font-family: "Roboto-Light";
}

.Roboto-Regular{
    font-family: "Roboto-Regular";
}