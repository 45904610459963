.button-fullscreen{
    color: #686868;
    min-width: 25px;
    background-color: transparent;
    border: 0;
    border-radius: 5px;
    padding: 0 4px 0 4px;
    margin-right: 2px;
    transition: all 0.2s;
}

.button-fullscreen:hover{
    color: black;
    background-color: #00000010;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.03);
}