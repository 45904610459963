.button-atelier-menu {
  scale: 1;
  height: 80px;
  width: 80px;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px 3px rgba(0, 0, 0, 0.25);
}

.button-atelier-menu:hover {
  scale: 1.1;
  box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.25);
}
