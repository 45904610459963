.atelier-localiser .content {
  height: calc(100% - 82px);
  overflow-y: auto;
}

.atelier-localiser .content::-webkit-scrollbar {
  width: 12px !important;
}

.atelier-localiser .content::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0, 0.5);
  border-radius: 6px !important;
}

.atelier-localiser .content::-webkit-scrollbar-track {
  border-radius: 6px !important;
  margin-bottom: 5px;
}

.accordion-button:not(.collapsed) {
  box-shadow: none;
  border: 1px solid #00727d;
  background-color: #00727d;
  color: white;
  border-radius: 0 !important;
  font-weight: bolder;
}
