.basic-input input{
    border-radius: 0;
    border: 0;
    border-bottom: solid 1px rgb(0, 0, 0, 0.2);
    transition: all 0.2s ease;
    background-color: transparent;
}

.basic-input input.error,
.basic-input input.error:focus,
.basic-input input.error:hover{
    color: #ff0000AA;
    box-shadow: inset 0px -3px 0px 0px #ff000059;
    border-bottom: solid 1px rgb(0, 0, 0, 0);
}

.basic-input input.error::placeholder,
.basic-input input.error:focus::placeholder,
.basic-input input.error:hover::placeholder{
    color: #ff0000AA;
}

.basic-input input::placeholder{
    transition: all 0.2s ease;
}

.basic-input input:focus{
    background-color: transparent;
    box-shadow: inset 0px -3px 0px 0px #00727d88;
    border-bottom: solid 1px rgb(0, 0, 0, 0);
}

.basic-input input:hover::placeholder,
.basic-input input:focus::placeholder{
    opacity: 0.5;
}

.basic-input input:disabled{
    border-radius: 8px;
    background-color: rgb(0, 0, 0, 0.025);
}

.hidden {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 0;
    height: 0;
}

.basic-input input:disabled::placeholder{
    opacity: 1;
}