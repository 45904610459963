.logo-text {
  fill: #000000;
  stroke-width: 0.267615;
}

.animated-logo polygon {
  stroke-width: 2;
}

.animated-logo polygon.hybride {
  stroke: #52b876;
  fill: #52b876;
}

.animated-logo polygon.textural {
  stroke: #00727d;
  fill: #00727d;
}

.animated-logo polygon.perform {
  stroke: #dacc2e;
  fill: #dacc2e;
}

.animated-logo polygon.aluminium {
  stroke: #859da9;
  fill: #859da9;
}

.animated-logo polygon.full {
  stroke: #000000;
  fill: #000000;
}

.animated-logo rect {
  fill: #000000;
  stroke: #000000;
  stroke-width: 1.62131;
  stroke-linejoin: round;
  stroke-opacity: 1;
}

.animated-logo .extension-text {
  font-family: "Montserrat";
  fill: #ffffff;
}

.animated-logo .title-muted-text {
  font-family: "Montserrat";
  fill: #000000;
  font-size: 12px;
}
