.dashboard-card-dark {
  border-radius: 10px;
  border: none;
  background-color: rgb(0, 0, 0, 0.045);
  margin-bottom: 10px;
}

.dashboard-card-light {
  border-radius: 10px;
  border: none;
  background-color: rgb(255, 255, 255, 0.5);
  margin-bottom: 10px;
}
