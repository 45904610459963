.operateur-groupe-icon {
  fill: none;
  fill-opacity: 0;
  stroke: currentColor;
  stroke-width: 7;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: none;
  stroke-opacity: 1;
}

.operateur-groupe-icon.stroke-6 {
  stroke-width: 6;
}

.operateur-groupe-icon.stroke-5 {
  stroke-width: 5;
}

.operateur-groupe-icon.stroke-4 {
  stroke-width: 4;
}

.operateur-groupe-icon.fill-25 {
  fill: currentColor;
  fill-opacity: 0.25;
}

.operateur-groupe-icon.fill-50 {
  fill: currentColor;
  fill-opacity: 0.5;
}

.operateur-groupe-icon.fill-100 {
  fill: currentColor;
  fill-opacity: 1;
}
