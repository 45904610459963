.form-navbar-secondaire .input-group input{
    min-width: 300px;
    font-size: 12px;
    padding: 4px 25px 4px 8px;
    background-color: #fbfcfc;
    border: solid #fbfcfc 1px;
    transition: all 0.15s ease;
}

.loupe{
    z-index: 6;
    top: 3px;
    right: 6px;
}

.form-navbar-secondaire .input-group input::placeholder,
.loupe{
    color: #888;
    transition: color 0.15s ease;
}

.form-navbar-secondaire .input-group input:hover{
    border: solid #ebebeb 1px;
    background-color: #f3f4f5;
}

.form-navbar-secondaire .input-group input:focus{
    border: solid #ebebeb 1px;
    box-shadow: inset 0px -2px 0px 0px #00727d88 !important;
    background-color: #ffffff;
}

.form-navbar-secondaire .input-group input:hover:focus,
.loupe:hover:focus{
    border: solid #ebebeb 1px;
    box-shadow: inset 0px -2px 0px 0px #00727d88 !important;
}

.form-navbar-secondaire .input-group input:focus::placeholder,
.loupe.focused{
    color: #bbb;
}