.poste-icon{
    fill:none;
    fill-opacity:0;
    stroke:currentColor;
    stroke-width:7;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-dasharray:none;
    stroke-opacity:1
}

.poste-icon.no-round{
    stroke-linecap:square;
}

.poste-icon.stroke-5{
    stroke-width:5;
}

.poste-icon.stroke-4{
    stroke-width:4;
}


.poste-icon.fill-25{
    fill:currentColor;
    fill-opacity:0.25;
}

.poste-icon.fill-50{
    fill:currentColor;
    fill-opacity:0.50;
}

.poste-icon.fill-100{
    fill:currentColor;
    fill-opacity:1;
}