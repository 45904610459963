html,
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Roboto-Regular";
  font-size: 12px !important;
  overflow: hidden;
}

/* CSS pour personnaliser la barre de défilement */
body::-webkit-scrollbar {
  width: 10px !important; /* Largeur de la barre de défilement */
}

body::-webkit-scrollbar-thumb {
  background-color: #888 !important; /* Couleur du curseur de la barre de défilement */
  border-radius: 6px !important; /* Bordure arrondie du curseur */
  width: 8px !important;
}

body::-webkit-scrollbar-track {
  background-color: #eee !important; /* Couleur de fond de la piste de la barre de défilement */
}

.col {
  padding-right: 0;
}

.scrollbox {
  height: 100vh;
}
