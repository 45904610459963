.UserDropdown{
    background-color: #ffffff00;
    padding: 0 !important;
    margin: 0px 2px !important;
    display: flex;
    align-items: center;
}

.UserDropdown:hover{
    background-color: #ffffff20;
}

.UserDropdown .dropdown-toggle::after {
    display: none;
  }