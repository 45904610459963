.stockage-type-icon{
    fill:none;
    fill-opacity:0;
    stroke:currentColor;
    stroke-width:7;
    stroke-linecap:round;
    stroke-linejoin:round;
    stroke-dasharray:none;
    stroke-opacity:1
}

.stockage-type-icon.stroke-5{
    stroke-width:5;
}

.stockage-type-icon.fill-25{
    fill:currentColor;
    fill-opacity:0.25;
}

.stockage-type-icon.fill-50{
    fill:currentColor;
    fill-opacity:0.50;
}

.stockage-type-icon.fill-100{
    fill:currentColor;
    fill-opacity:1;
}