.dropdown-second-nav-bar button {
  border-radius: 4px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-right: 2px;
  color: #686868 !important;
  background-color: transparent;
  border: none;
  font-size: 12px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.dropdown-second-nav-bar button:hover,
.dropdown-second-nav-bar button.show,
.dropdown-second-nav-bar button.active,
.dropdown-second-nav-bar button.btn:active {
  color: #000000 !important;
  background-color: #00000010;
  border-radius: 4px;
}

.dropdown-second-nav-bar .dropdown-menu {
  transform: translateY(-30px);
  opacity: 0;
  margin-top: 7px;
  background-color: #f3f4f5;
  border: solid 1px #00000006;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
}

.dropdown-second-nav-bar .dropdown-menu.show {
  transform: translateY(0);
  opacity: 1;
  transition: transform 1s ease, opacity 1s ease;
}

.dropdown-second-nav-bar .dropdown-item {
  padding: 0px 5px;
}

.dropdown-second-nav-bar .dropdown-item:hover {
  background-color: transparent;
}

.dropdown-second-nav-bar .dropdown-item > a {
  border-radius: 4px;
  padding: 5px 8px;
  display: block;
  width: 100%;
  text-decoration: none;
  font-size: 14px;
  color: #686868;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.dropdown-second-nav-bar .dropdown-item > a:hover {
  color: #000000 !important;
  background-color: #00000010;
}
