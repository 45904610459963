.conteneur-table{
    overflow-y: auto;
}

tr {
    background: transparent;
    transition: all 0.2s ease;
    cursor: pointer;
}

tr:hover {
    background-color: rgb(0, 0, 0, 0.035);
}

.table-fixe thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    background-color: #ffffff !important;
    font-size: 0.70rem;
}

.table-fixe thead td {
    padding: 0.4rem 0.5rem 0.1rem 0.5rem !important;
    transition: background-color 0.3s ease;
    color: #777;
    cursor: pointer;
}

.table-fixe thead td:hover {
    background-color: #f0ebab55;
}

.table-fixe tbody {
    padding: 0.3rem 0.3rem !important;
    vertical-align: middle !important;
    font-size: 0.75rem;
}

.table-fixe tbody td {
    color: #444 !important;
    background-color: transparent !important;
}

.conteneur-table::-webkit-scrollbar {
    width: 8px !important;
}

.conteneur-table::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px !important;
}

.conteneur-table::-webkit-scrollbar-track {
    background-color: none;
}