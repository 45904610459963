.authentification-wrapper{
    min-height: 100vh;
    position: relative;
}

.authentification-wrapper .tab-content{
    background: #f3f4f5;
    padding: 35px;
    padding-top: 60px;
    text-align: center;
}

.authentification-wrapper.radius-top-left .tab-content{
    border-radius: 0px 10px 10px 10px;
}

.authentification-wrapper .tab-content .radius-top-right{
    border-radius: 10px 0px 10px 10px;
}

.authentification-wrapper .nav-item button.active,
.authentification-wrapper .nav-item button{
    padding-top: 13px;
    font-family: "Montserrat-SemiBold";
    font-size: 1rem;
    border: none;
}

.authentification-wrapper .nav-item button{
    border-radius: 10px 10px 0px 0px;
    color: rgb(0, 0, 0, 0.4);
}

.authentification-wrapper .nav-item button:hover{
    color: rgb(0, 0, 0, 0.8);
}

.authentification-wrapper .nav-item button.active{
    background: #f3f4f5;
}

.authentification-wrapper .nav-tabs{
    border-bottom-width: 0px !important;
}