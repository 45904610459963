.password-input input {
  border-radius: 0;
  border: 0;
  border-bottom: solid 1px rgb(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  background-color: transparent;
}

.password-input input.error,
.password-input input.error:focus,
.password-input input.error:hover {
  color: #ff0000aa;
  box-shadow: inset 0px -3px 0px 0px #ff000059;
  border-bottom: solid 1px rgb(0, 0, 0, 0);
}

.password-input input.error::placeholder,
.password-input input.error:focus::placeholder,
.password-input input.error:hover::placeholder {
  color: #ff0000aa;
}

.password-input input::placeholder {
  transition: all 0.2s ease;
}

.password-input input:focus {
  background-color: transparent;
  box-shadow: inset 0px -3px 0px 0px #00727d88;
  border-bottom: solid 1px rgb(0, 0, 0, 0);
}

.password-input input:hover::placeholder,
.password-input input:focus::placeholder {
  opacity: 0.5;
}

.password-input input:disabled {
  border-radius: 8px;
  background-color: rgb(0, 0, 0, 0.025);
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
}

.password-input input:disabled::placeholder {
  opacity: 1;
}
