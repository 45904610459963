.switch-view input{
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba(0, 0, 0, 0.25)%27/%3e%3c/svg%3e") !important;
    background-color: white !important;
    border-color: #adb5bd !important; 
}

.switch-view input:hover{
    cursor: pointer;
}

.switch-view input:checked{
    background-color: white !important;
}

.switch-view input:focus{
    box-shadow: none !important;
}