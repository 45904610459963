.footer{
    background:#f3f4f5 !important;
    height: 30px !important;
    margin: 0 auto !important;
    padding: 10 0 !important;
    box-shadow: none !important;
}

.footer.navbar{
    padding: 0 !important;
    border-top: solid 1px #00000011 !important;
    font-size: 13px;
}