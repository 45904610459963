.tool-navbar{
    background:transparent !important;
    height: 45px !important;
    margin: 0 auto !important;
    padding: 10px 0 !important;
    box-shadow: none !important;
    padding: 0 !important;
    border-bottom: solid 1px #00000022 !important;
    font-size: 13px !important;
}

.tool-navbar .nav-link{
    color: #000000 !important;
}

.tool-navbar .nav-link:hover{
    border-radius: 5px !important;
    background-color: #00000010;
}