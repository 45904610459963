.big-input-search input {
  font-size: 1.5rem;
  border-radius: 5px;
  height: 70px;
  transition: all 0.2s ease;
  background-color: transparent;
  padding-right: 60px;
}

.big-input-search input.error,
.big-input-search input.error:focus,
.big-input-search input.error:hover {
  color: #ff0000aa;
  box-shadow: inset 0px -3px 0px 0px #ff000059;
  border-bottom: solid 1px rgb(0, 0, 0, 0);
}

.big-input-search input.error::placeholder,
.big-input-search input.error:focus::placeholder,
.big-input-search input.error:hover::placeholder {
  color: #ff0000aa;
}

.big-input-search input::placeholder {
  font-size: 1.5rem;
  opacity: 0.5;
  color: black;
  transition: all 0.2s ease;
}

.big-input-search input:focus {
  background-color: transparent;
  box-shadow: none;
}

.big-input-search input:disabled {
  border-radius: 8px;
  background-color: rgb(0, 0, 0, 0.025);
}

.hidden {
  opacity: 0;
  position: absolute;
  pointer-events: none;
  width: 0;
  height: 0;
}

.big-input-search input:disabled::placeholder {
  opacity: 1;
}
