.bg-body-proferm {
  background: #d7dbdc !important;
}

.top-navbar {
  z-index: 1030 !important;
  height: 38px !important;
  margin: 0 auto !important;
  padding: 10px 0 !important;
  border-bottom: solid 1px #00000022 !important;
}

.top-navbar .navbar {
  padding: 0px 0px 0px 8px !important;
  border-bottom: solid 1px #00000011 !important;
  font-size: 13px;
}

.top-navbar .navbar-brand {
  color: #686868 !important;
  transition: all 0.3s ease;
  padding: 0px 3px 3px 3px !important;
  margin: 2px 1px 2px 8px !important;
  border-radius: 4px;
}

.top-navbar .navbar-brand:hover {
  color: #000000 !important;
  background-color: #00000010;
}

.top-navbar .nav-link {
  border-radius: 4px;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  margin-right: 2px;
  color: #686868 !important;
}

.top-navbar .nav-link:hover {
  color: #000000 !important;
  background-color: #00000010;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.top-navbar .nav-link.active {
  color: #000000 !important;
  background-color: #00000010;
  border-radius: 4px;
}

.top-navbar .navbar-toggler,
.top-navbar .navbar-toggler:focus,
.top-navbar .navbar-toggler:active,
.top-navbar .navbar-toggler-icon:focus {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}
