.custom-timeline .vertical-timeline-element-content {
  background: none;
  box-shadow: none;
}

.custom-timeline .description {
  padding: 20px;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 15px;
  box-shadow: 0px 10px 15px 0px #00000005;
}
