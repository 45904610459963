.button-page{
    color: inherit;
    min-width: 25px;
    background-color: rgba(0, 0, 0, 0.1);
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    padding: 0 4px 0 4px;
    margin-right: 2px;
    transition: all 0.2s;
}

.button-page:disabled,
.button-page:disabled:hover{
    color: rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.03);
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.03);
}

.button-page:hover{
    background-color: rgba(0, 0, 0, 0.2);
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.03);
}

.button-page.active{
    background-color: rgba(0, 0, 0, 0.01);
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.3);
}