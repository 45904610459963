.icon-card{
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 0px 10px 10px 10px !important;
}

.resizableTable-card{
    background: transparent;
    transition: all 0.2s ease;
    cursor: pointer;
}
.resizableTable-card:hover{
    background-color: rgb(0,0,0, 0.035);
}